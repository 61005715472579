import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";
import {
  Wrapper,
  BlockContent,
  ProjectThumbnailCard,
  Button,
} from "../components";
import { logoPuppetbrain } from "../assets/graphics";

import theme from "../constants/theme";

/**
 * IndexPage
 */
const IndexPage = ({ data }) => {
  const _generateThumbnails = arr => {
    return arr.map(({ node }, index) => {
      const to = `/work/${node.slug.current}`;
      const title = node.title ?? "No title";
      const skills = node.skills ?? [];
      const coverImageUrl =
        node.coverImage?.asset?.fluid?.src ??
        "https://benzai.github.io/shits-test-assets/avatars/puang.png";

      return (
        <ProjectThumbnailCard
          key={node.id}
          to={to}
          title={title}
          skills={skills}
          imageUrl={coverImageUrl}
          isShowCoverImageBorder={node.isShowCoverImageBorder}
        />
      );
    });
  };

  const personTextBlock = () => {
    return (
      <div className="flex flex-col items-center max-w-300 md:max-w-400 lg:max-w-500">
        <h1 className="font-medium text-md2 md:text-md lg:text-lg4 text-text1 tracking-tight_md leading-none text-center">
          Design and illustration portfolio of Jenneke Choe.
        </h1>
        <div className="bg-transparent h-10 lg:h-20" />
        <p className="text-md4 lg:text-md4 text-text3 leading-normal text-center">
          Freelance designer based in Rotterdam, The Netherlands. Specialized in
          product illustration, character design, icon design and animation.
        </p>
        <div className="bg-transparent h-20 md:h-30 lg:h-40" />
        <Button
          to="/#work"
          label="View my work"
          labelColor={"text-accent1"}
          frameColor={"bg-accent1_25"}
          hoverFrameColor={"bg-accent1_15"}
        />
      </div>
    );
  };

  return (
    <Layout>
      <SEO title="Home" />
      <div className="bg-bg1">
        <section id="hero">
          <Wrapper>
            <div className="flex flex-col items-center py-40 lg:py-60">
              {personTextBlock()}
            </div>
          </Wrapper>
        </section>

        <section id="work" className="px-20 lg:px-60 xl:px-80">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-15 md:gap-20 pb-60">
            {_generateThumbnails(data.allSanityProject.edges)}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanityProject(
      filter: { isActive: { eq: true } }
      sort: { fields: sortOrder, order: DESC }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          category {
            title
          }
          skills {
            title
          }
          client
          coverImage {
            asset {
              fluid {
                src
              }
            }
          }
          isShowCoverImageBorder
        }
      }
    }
  }
`;

export default IndexPage;
